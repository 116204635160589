<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            text=""
            default-href="/"
          />
        </ion-buttons>
        <ion-title>{{$t('contact.us')}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding-start ion-padding-end pt-5">
      <div class="contact-page tablet-centered">
        <form class="mb-5">
          <AppInput
            :label="$t('contact.name')"
            :errors="errors?.name"
            :value="name"
            class="ion-margin-bottom"
            @input:update="name = $event"
          />
          <AppInput
            :label="$t('contact.email')"
            type="email"
            :errors="errors?.email"
            :value="email"
            class="ion-margin-bottom"
            @input:update="email = $event"
          />
          <AppInput
            :label="$t('contact.subject')"
            :errors="errors?.subject"
            :value="subject"
            class="ion-margin-bottom"
            @input:update="subject = $event"
          />
          <ion-item-group class="checkbox-group ion-margin-bottom">
            <ion-label class="fz-14">{{$t('contact.contactVia')}}</ion-label>
            <ion-item>
              <ion-label>{{$t('contact.email')}}</ion-label>
              <ion-checkbox
                v-model="isEmail"
                color="medium"
                class="
                  checkbox-app
                  --checkbox-border-radius
                  --checkbox-background
                  --checkbox-border-width
                  --checkmark-width
                  --checkbox-size
                "
              />
            </ion-item>
            <ion-item>
              <ion-label>{{$t('contact.phone')}}</ion-label>
              <ion-checkbox
                v-model="isPhone"
                color="medium"
                class="
                  checkbox-app
                  --checkbox-border-radius
                  --checkbox-background
                  --checkbox-border-width
                  --checkmark-width
                  --checkbox-size
                "
              />
            </ion-item>
          </ion-item-group>
          <AppInput
            :label="$t('contact.phone')"
            type="tel"
            maxlength="14"
            :errors="errors?.phone_number"
            class="ion-margin-bottom"
            @input:update="phone = $event"
          />
          <div>
            <ion-label>{{$t('contact.message')}}</ion-label>
          </div>
          <div class="ion-margin-bottom">
            <ion-item class="textarea-container">
              <IonTextarea
                v-model="message"
                :rows="5"
              />
            </ion-item>
            <AppErrorField :errors="errors?.message" />
          </div>
          <div class="submit-btn-wrap is-flex ion-justify-content-center">
            <ion-button
              mode="ios"
              fill="outline"
              class="uppercase --border-radius submit-btn"
              @click="submitContact"
            >
              {{$t('commonKeys.save')}}
            </ion-button>
          </div>
        </form>
        <small>
          {{ $t('contact.googlePowered') }}
          <a
            href="#"
            @click.prevent="openUrl('https://policies.google.com/privacy')"
          >
            {{ $t('contact.googlePrivacy') }}
          </a>
          &
          <a
            href="#"
            @click.prevent="openUrl('https://policies.google.com/terms')"
          >
            {{ $t('contact.googleTerm') }}
          </a>
          {{ $t('contact.apply') }}.
        </small>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import AppErrorField from '@/components/common/AppErrorField.vue';
import useRequestToast from '@/composables/useRequestToast';
import useInAppBrowser from '@/composables/useInAppBrowser';
import { useI18n } from 'vue-i18n/index';

import { CONTACT_FORM } from '@/config/api';

import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
  IonLabel,
  IonCheckbox,
  IonItem,
  IonList,
  IonItemGroup,
  IonTextarea,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonTitle,
} from '@ionic/vue';

import { useReCaptcha } from 'vue-recaptcha-v3';
import { ref } from '@vue/reactivity';
import axios from '@/http';

import { useStore } from 'vuex';

export default {
  name: 'Contact',
  components: {
    AppInput,
    AppErrorField,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    IonLabel,
    IonCheckbox,
    IonItem,
    IonList,
    IonItemGroup,
    IonTextarea,
    IonHeader,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonTitle,
  },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const { openErrorToast, openSuccessToast } = useRequestToast();
    const { openUrl } = useInAppBrowser();
    const { t } = useI18n();
    const store = useStore();

    const successMessage = `${t('contact.popupMessage')}<br>${t('contact.popupTitle')}`;

    const name = ref(store.state.profile.profileDetails.first_name);
    const email = ref(store.getters['authentication/getUser']?.email ?? null);
    const subject = ref(null);
    const phone = ref(null);
    const message = ref(null);
    const isEmail = ref(null);
    const isPhone = ref(null);
    const errors = ref(null);

    const getRecaptchaToken = async () => {
      await recaptchaLoaded();
      return await executeRecaptcha('login');
    };

    const submitContact = async () => {
      const recaptcha = await getRecaptchaToken();
      axios
        .post(CONTACT_FORM, {
          name: name.value,
          email: email.value,
          contact_via: [isEmail.value, isPhone.value],
          phone_number: phone.value,
          subject: subject.value,
          message: message.value,
          recaptcha,
        })
        .then(() => {
          openSuccessToast(successMessage);
          resetForm();
          errors.value = null;
        })
        .catch((err) => {
          errors.value = err?.response?.data?.errors;
          openErrorToast(err);
        });
    };

    const resetForm = () => {
      name.value = null;
      email.value = null;
      subject.value = null;
      phone.value = null;
      message.value = null;
    };

    return {
      name,
      email,
      subject,
      isEmail,
      isPhone,
      phone,
      message,
      submitContact,
      resetForm,
      errors,
      openUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.contact-page {
  * {
    --inner-border-width: 0;
  }
  .checkbox-group * {
    font-size: 14px;
  }

  .textarea-container {
    --padding-start: 10px;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-end: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    --border-radius: 10px;
    --border-style: solid;
    --border-width: 0px;
    --border-color: #fff;
    --background: #282828;
    --min-height: 50px;
  }

  ion-textarea {
    --background: transparent;
    --padding-start: 10px;
    --color: var(--white);
    --placeholder-color: var(--white);
    font-size: 14px;
  }

  .submit-btn {
    --padding-start: 50px;
    --padding-end: 50px;
  }
}
</style>
